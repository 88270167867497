import { useClientConfig } from './ClientConfig'
import { ensureInteger, ensureString, ensureArray } from '/machinery/ensureValue'

export function useNormalizedJobFilterValues(queryString) {
  const config = useClientConfig()
  const salaryRange = config.salary.filter.range
  return React.useMemo(
    () => normalizeJobFilterValues(queryString, salaryRange),
    [queryString, salaryRange]
  )
}

export function useNormalizedTechblogFilterValues(queryString) {
  return React.useMemo(
    () => ({
      page: ensureInteger(queryString.page, 1),
      category: ensureArray(queryString.category),
      tag: ensureArray(queryString.tag),
    }),
    [queryString]
  )
}

export function useNormalizedTopicsFilterValues(queryString) {
  return React.useMemo(
    () => ({
      page: ensureInteger(queryString.page, 1),
      tag: ensureArray(queryString.tag),
      q: ensureString(queryString.q),
    }),
    [queryString]
  )
}

export function normalizeJobFilterValues({
  page = undefined,
  q = undefined,
  country = undefined,
  remote_type = undefined,
  contract_type = undefined,
  job_branch = undefined,
  job_type = undefined,
  sub_expertise = undefined,
  salary_scale_min = undefined,
  salary_scale_max = undefined
}, salaryRange) {

  const normalizedSalaryScaleMin = ensureInteger(salary_scale_min, salaryRange.min)
  const normalizedSalaryScaleMax = ensureInteger(salary_scale_max, salaryRange.max)
  const normalizedPage = ensureInteger(page, 1)

  return {
    page: normalizedPage,
    q: q ? ensureString(q) : undefined,
    country: country ? ensureString(country) : undefined,
    remote_type: ensureArray(remote_type),
    contract_type: ensureArray(contract_type),
    job_branch: ensureArray(job_branch),
    job_type: ensureArray(job_type),
    sub_expertise: ensureArray(sub_expertise),
    salary_scale_min: normalizedSalaryScaleMin === salaryRange.min ? undefined : normalizedSalaryScaleMin,
    salary_scale_max: normalizedSalaryScaleMax === salaryRange.max ? undefined : normalizedSalaryScaleMax,
  }
}
